.game-holder {
	position: absolute;
	width: 100%;
	height: 100%;
	background: -webkit-linear-gradient(#e4e0ba, #f7d9aa);
	background: linear-gradient(#e4e0ba, #f7d9aa);
}

.world {
	position: absolute;
	overflow: hidden;
	width: 100%;
	height: 100%;
}

.header {
	position: absolute;
	top: 8vh;
	left: 0;
	width: 100%;
	text-align: center;
	pointer-events: none;
	z-index: 1;
}

.header h1 {
	font-family: 'Playfair Display';
	font-size: 4.5em;
	line-height: 1;
	margin: 0;
	letter-spacing: -0.025em;
	color: #d1b790;
}

.header h1 span {
	font-size: 0.2em;
	font-style: italic;
	display: block;
	margin: 0 0 -1.5em -7em;
	letter-spacing: 0px;
}

.header h2 {
	font-size: 0.585em;
	margin: 0.25em 0;
	white-space: nowrap;
	text-indent: 1em;
	letter-spacing: 1em;
	text-transform: uppercase;
	color: #d6483b;
}

.score {
	width: 100%;
	margin: 2em 0 0;
	text-align: center;
	white-space: nowrap;
}

.score__content {
	position: relative;
	display: inline-block;
	padding: 0 1em;
	vertical-align: top;
}

.score__content:nth-child(2) {
	border-right: 1px solid #d1b790;
	border-left: 1px solid #d1b790;
}

.score__label {
	font-size: 9px;
	position: relative;
	margin: 0 0 0.5em 0;
	text-align: center;
	letter-spacing: 3px;
	text-transform: uppercase;
	color: #d1b790;
}

.score__value {
	font-family: 'Playfair Display';
	font-weight: bold;
	color: #d1b790;
}

.score__value--level {
	font-size: 26px;
}

.score__value--dist {
	font-size: 30px;
}

.level-circle {
	position: absolute;
	left: 50%;
	width: 46px;
	margin: -37px 0 0 -23px;
	-webkit-transform: rotate(-90deg);
	transform: rotate(-90deg);
}

.score__value--energy {
	position: relative;
	width: 60px;
	height: 8px;
	margin-top: 20px;
	border-radius: 3px;
	background-color: #d1b790;
}

.energy-bar {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	margin: 2px;
	background-color: #f25346;
	-webkit-animation-name: none;
	animation-name: none;
	-webkit-animation-duration: 150ms;
	animation-duration: 150ms;
	-webkit-animation-iteration-count: infinite;
	animation-iteration-count: infinite;
}

.message {
	font-weight: bold;
	position: absolute;
	left: 0;
	width: 100%;
	text-align: center;
	text-transform: uppercase;
	pointer-events: none;
}

.message--replay {
	font-size: 1.25vw;
	bottom: 40vh;
	display: none;
	text-indent: 0.5em;
	letter-spacing: 0.5em;
	color: #d1b790;
}

.message--instructions {
	font-family: 'Playfair Display';
	font-size: 0.85em;
	bottom: 8vh;
	letter-spacing: 0.2em;
	color: #68c3c0;
}

.message--instructions span {
	display: block;
	color: #d6483b;
	white-space: nowrap;
}

@-webkit-keyframes blinking {
	0% { opacity: 1; }
	50% { opacity: 0; }
	100% { opacity: 1; }
}

@keyframes blinking {
	0% { opacity: 1; }
	50% { opacity: 0; }
	100% { opacity: 1; }
}

@media screen and (max-width: 40em) {
	.header {
		font-size: 0.75em;
		top: 6.5em;
	}
	.header h2 {
		letter-spacing: 0.65em;
	}
}
