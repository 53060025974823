html {
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    background: #FFFFFF;
    height: 100%;
    overflow: inherit!important;
}
body{
    min-height: 100%;
    overflow: inherit!important;
}
html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
menu,
nav,
section,
summary,
time,
mark,
audio,
video,
input,
main {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    vertical-align: baseline;
    border: 0;
    outline: 0;
    font-family:PingFangSC-Regular;
}

* {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
    min-width: 320px;
    max-width: 1920px;
    margin: 0 auto;
    font-size: 14px;
    background-color: #F2F3F8;
}
img,
object {
    vertical-align: top;
}
textarea,
input {
    outline: none;
    *outline: expression(this.hideFocus=true);
}

button::-moz-focus-inner,
input[type="reset"]::-moz-focus-inner,
input[type="button"]::-moz-focus-inner,
input[type="submit"]::-moz-focus-inner,
input[type="file"]>input[type="button"]::-moz-focus-inner {
    border: none;
    padding: 0;
}

input {
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    appearance: none;
}

a {
    text-decoration: none;
    outline: none;
    cursor: pointer;
    *outline: expression(this.hideFocus=true);
}

a:visited {
    /*color: #787878;*/
}

ul,
li {
    list-style: none;
    margin: 0;
    padding: 0;
}

.fn-clear {
    zoom: 1;
}

.fn-clear:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: "1";
    clear: both;
    height: 0;
}

.align-justify {
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    -webkit-align-items: center;
    -webkit-box-align: center;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -webkit-box-pack: justify;
}

.justify-center {
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    -webkit-align-items: center;
    -webkit-box-align: center;
    justify-content: center;
    -webkit-justify-content: center;
    -webkit-box-pack: center;
}

.flexible {
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    -webkit-align-items: center;
    -webkit-box-align: center;
}

.flexible-start {
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    align-items: flex-start;
    -webkit-align-items: flex-start;
    -webkit-box-align: start;
}
.flexible-column{
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
}

.col-1 {
    flex: 1;
    -webkit-flex: 1;
    -webkit-box-flex: 1;
}
.col-1::-webkit-input-placeholder{
    font-size: .24rem;
}
.col-1::-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    font-size: .24rem;
}
.col-1::-moz-placeholder { /* Mozilla Firefox 19+ */
    font-size: .24rem;
}
.col-1:-ms-input-placeholder { /* Internet Explorer 10+ */
    font-size: .24rem;
}

.ellipsis-one {
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.ellipsis-two {
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.ellipsis-three {
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}
