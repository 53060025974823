body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    background: #FFFFFF;
    height: 100%;
    overflow: inherit!important;
}
body{
    min-height: 100%;
    overflow: inherit!important;
}
html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
menu,
nav,
section,
summary,
time,
mark,
audio,
video,
input,
main {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    vertical-align: baseline;
    border: 0;
    outline: 0;
    font-family:PingFangSC-Regular;
}

* {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
    min-width: 320px;
    max-width: 1920px;
    margin: 0 auto;
    font-size: 14px;
    background-color: #F2F3F8;
}
img,
object {
    vertical-align: top;
}
textarea,
input {
    outline: none;
    *outline: expression(this.hideFocus=true);
}

button::-moz-focus-inner,
input[type="reset"]::-moz-focus-inner,
input[type="button"]::-moz-focus-inner,
input[type="submit"]::-moz-focus-inner,
input[type="file"]>input[type="button"]::-moz-focus-inner {
    border: none;
    padding: 0;
}

input {
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    appearance: none;
}

a {
    text-decoration: none;
    outline: none;
    cursor: pointer;
    *outline: expression(this.hideFocus=true);
}

a:visited {
    /*color: #787878;*/
}

ul,
li {
    list-style: none;
    margin: 0;
    padding: 0;
}

.fn-clear {
    zoom: 1;
}

.fn-clear:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: "1";
    clear: both;
    height: 0;
}

.align-justify {
    display: flex;
    align-items: center;
    -webkit-align-items: center;
    -webkit-box-align: center;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -webkit-box-pack: justify;
}

.justify-center {
    display: flex;
    align-items: center;
    -webkit-align-items: center;
    -webkit-box-align: center;
    justify-content: center;
    -webkit-justify-content: center;
    -webkit-box-pack: center;
}

.flexible {
    display: flex;
    align-items: center;
    -webkit-align-items: center;
    -webkit-box-align: center;
}

.flexible-start {
    display: flex;
    align-items: flex-start;
    -webkit-align-items: flex-start;
    -webkit-box-align: start;
}
.flexible-column{
    display: flex;
    flex-direction: column;
}

.col-1 {
    flex: 1 1;
    -webkit-flex: 1;
    -webkit-box-flex: 1;
}
.col-1::-webkit-input-placeholder{
    font-size: .24rem;
}
.col-1::-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    font-size: .24rem;
}
.col-1::-moz-placeholder { /* Mozilla Firefox 19+ */
    font-size: .24rem;
}
.col-1:-ms-input-placeholder { /* Internet Explorer 10+ */
    font-size: .24rem;
}

.ellipsis-one {
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.ellipsis-two {
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.ellipsis-three {
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

@font-face {
	font-family: 'codropsicons';
	font-weight: normal;
	font-style: normal;
	src: url(/static/media/codropsicons.e0c41e21.eot);
	src: url(/static/media/codropsicons.e0c41e21.eot?#iefix) format('embedded-opentype'), url(/static/media/codropsicons.70b87227.woff) format('woff'), url(/static/media/codropsicons.89060dae.ttf) format('truetype'), url(/static/media/codropsicons.1958497a.svg#codropsicons) format('svg');
}

*,
*::after,
*::before {
	box-sizing: border-box;
}

body {
	font-family: 'Avenir Next', Avenir, 'Helvetica Neue', Helvetica, Arial, sans-serif;
	margin: 0;
	color: #444;
	background: #f6f6f6;
}

a {
	text-decoration: none;
	color: #d1b790;
	outline: none;
}

a:hover,
a:focus {
	color: #bba077;
	outline: none;
}


/* Top Navigation Style */

.meta {
	font-size: 0.75em;
	line-height: 1;
	position: absolute;
	top: 1em;
	left: 1em;
	display: flex;
	flex-wrap: wrap;
	white-space: nowrap;
	border-top: 1px solid #d1b790;
}

.meta::after {
	content: '';
	position: absolute;
	top: 0;
	right: 0;
	width: 1px;
	height: 100%;
	background: #d1b790;
}

.meta a {
	display: block;
	padding: 1em 1.35em;
	border: 1px solid #d1b790;
	border-top: 0;
}

.meta a:not(:last-child) {
	border-right: 0;
}

.codrops-icon {
	width: 4em;
}

.codrops-icon span {
	display: none;
}

.codrops-icon::before {
	font-family: 'codropsicons';
	font-size: 1.15em;
	font-weight: normal;
	font-style: normal;
	font-feature-settings: normal;
	font-variant: normal;
	line-height: 1;
	text-transform: none;
	speak: none;
	-webkit-font-smoothing: antialiased;
}

.codrops-icon--drop::before {
	content: '\e001';
	color: #09c;
}

.codrops-icon--prev::before {
	content: '\e004';
}


/* Demo links */

.demo-link {
	font-family: 'Playfair Display';
	font-weight: bold;
	text-align: center;
	letter-spacing: 0.2em;
	text-transform: uppercase;
}

.demo-link--current {
	color: #bba077;
}

@media screen and (max-width:40em) {
	.meta {
		right: 1em;
	}
	.demo-link {
		flex: 1 1;
	}
}

/* Styles for sponsor */
body #cdawrap { top: auto; right: auto; bottom: 12px; left: 12px; border: 1px solid #d1b790; background: none; }
.partisan { position: absolute; bottom: 0; left: 0; width: 38em; padding: 4.5em 7.5em 1.5em 5.5em; }
.partisan__bg { position: absolute; bottom: 0; left: 0; width: 100%; height: 100%; pointer-events: none; opacity: 0.5; }
.partisan__link { position: relative; display: flex; justify-content: center; align-items: center; }
.partisan__img, .partisan__title { opacity: 0.85; transition: opacity 0.1s; }
.partisan__link:hover .partisan__img, .partisan__link:hover .partisan__title { opacity: 1; }
.partisan__img { max-width: 42%; }
.partisan__title { font-family: 'Playfair Display'; font-weight: bold; position: relative; margin: 0.5em 0 0 0.85em; color: #555d27; }
.partisan__title::before { content: 'Sponsored by:'; font-size: 0.5em; font-weight: bold; position: absolute; bottom: 100%; left: 0; padding: 0 0 1em 0; letter-spacing: 0.25em; text-transform: uppercase; color: #95a534; }
@media screen and (max-width:80em) {
	.partisan { font-size: 76%; }
	.partisan__title::before { font-size: 0.25em; }
}
@media screen and (max-width:60em) {
	.game-holder .message--instructions { bottom: 5.5em; }
	.partisan { width: 100%; height: auto; padding: 1.75em 0.5em 0.5em; text-align: center; background: rgba(190, 215, 48, 0.5); }
	.partisan__bg { display: none; }
	.partisan__img { display: none; }
	.partisan__title { margin: 0; }
	.partisan__title::before { width: 100%; padding: 0 0 0.25em; }
}

.game-holder {
	position: absolute;
	width: 100%;
	height: 100%;
	background: linear-gradient(#e4e0ba, #f7d9aa);
}

.world {
	position: absolute;
	overflow: hidden;
	width: 100%;
	height: 100%;
}

.header {
	position: absolute;
	top: 8vh;
	left: 0;
	width: 100%;
	text-align: center;
	pointer-events: none;
	z-index: 1;
}

.header h1 {
	font-family: 'Playfair Display';
	font-size: 4.5em;
	line-height: 1;
	margin: 0;
	letter-spacing: -0.025em;
	color: #d1b790;
}

.header h1 span {
	font-size: 0.2em;
	font-style: italic;
	display: block;
	margin: 0 0 -1.5em -7em;
	letter-spacing: 0px;
}

.header h2 {
	font-size: 0.585em;
	margin: 0.25em 0;
	white-space: nowrap;
	text-indent: 1em;
	letter-spacing: 1em;
	text-transform: uppercase;
	color: #d6483b;
}

.score {
	width: 100%;
	margin: 2em 0 0;
	text-align: center;
	white-space: nowrap;
}

.score__content {
	position: relative;
	display: inline-block;
	padding: 0 1em;
	vertical-align: top;
}

.score__content:nth-child(2) {
	border-right: 1px solid #d1b790;
	border-left: 1px solid #d1b790;
}

.score__label {
	font-size: 9px;
	position: relative;
	margin: 0 0 0.5em 0;
	text-align: center;
	letter-spacing: 3px;
	text-transform: uppercase;
	color: #d1b790;
}

.score__value {
	font-family: 'Playfair Display';
	font-weight: bold;
	color: #d1b790;
}

.score__value--level {
	font-size: 26px;
}

.score__value--dist {
	font-size: 30px;
}

.level-circle {
	position: absolute;
	left: 50%;
	width: 46px;
	margin: -37px 0 0 -23px;
	transform: rotate(-90deg);
}

.score__value--energy {
	position: relative;
	width: 60px;
	height: 8px;
	margin-top: 20px;
	border-radius: 3px;
	background-color: #d1b790;
}

.energy-bar {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	margin: 2px;
	background-color: #f25346;
	-webkit-animation-name: none;
	animation-name: none;
	-webkit-animation-duration: 150ms;
	animation-duration: 150ms;
	-webkit-animation-iteration-count: infinite;
	animation-iteration-count: infinite;
}

.message {
	font-weight: bold;
	position: absolute;
	left: 0;
	width: 100%;
	text-align: center;
	text-transform: uppercase;
	pointer-events: none;
}

.message--replay {
	font-size: 1.25vw;
	bottom: 40vh;
	display: none;
	text-indent: 0.5em;
	letter-spacing: 0.5em;
	color: #d1b790;
}

.message--instructions {
	font-family: 'Playfair Display';
	font-size: 0.85em;
	bottom: 8vh;
	letter-spacing: 0.2em;
	color: #68c3c0;
}

.message--instructions span {
	display: block;
	color: #d6483b;
	white-space: nowrap;
}

@-webkit-keyframes blinking {
	0% { opacity: 1; }
	50% { opacity: 0; }
	100% { opacity: 1; }
}

@keyframes blinking {
	0% { opacity: 1; }
	50% { opacity: 0; }
	100% { opacity: 1; }
}

@media screen and (max-width: 40em) {
	.header {
		font-size: 0.75em;
		top: 6.5em;
	}
	.header h2 {
		letter-spacing: 0.65em;
	}
}

.world {
	position: absolute;
	width: 100%;
	height: 100%;
	overflow: hidden;
	background: linear-gradient(#e4e0ba, #f7d9aa);
}
